import * as React from 'react';
import Layout from '../../components/layout';
import { Header } from '../../components/organisms/Header';
import NeedMore from '../../components/organisms/NeedMore';
import { graphql } from 'gatsby';
import PageContent from '../../components/organisms/PageContent';
import AboutList from '../../components/organisms/AboutList';
import NewestNews from '../../components/organisms/NewestNews';
import DataboutTools from '../../components/organisms/DataboutTools';
import SEO from '../../components/seo';
import lisHeader from '../../images/lis-page/lis-header.svg';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getPostModel, WpPost } from '../../shared/wp-post-utils';
import { defaultLanguage } from '../../../site-config';
import Img from "gatsby-image";
import { ImageSharp } from '../../shared/image-sharp-utils';
import styled from 'styled-components';
import Button from '../../components/atoms/Button';

type LisItem = {
  lis?: {
    description: string;
    fieldGroupName: string;
    lisaboutlistitem1text: string;
    lisaboutlistitem1title: string;
    lisaboutlistitem2text: string;
    lisaboutlistitem2title: string;
    lisaboutlistitem3text: string;
    lisaboutlistitem3title: string;
    lisaboutlistitemstitle: string;
    subtitle: string;
    title: string;
    lisaboutlistitem1img: {
      localFile: {
        childImageSharp: ImageSharp;
      }
    }
    lisaboutlistitem2img: {
      localFile: {
        childImageSharp: ImageSharp;
      }
    }
    lisaboutlistitem3img: {
      localFile: {
        childImageSharp: ImageSharp;
      }
    }
    reportimage: {
      localFile: {
        childImageSharp: ImageSharp;
      }
    }
  }
}

type LisData = {
  data: {
    parent: WpPost & LisItem;
    children: {
      nodes: WpPost[];
    };
    newestNews: {
      nodes: WpPost[];
    };
  };
  location?: any;
}

export const StyledLisContent = styled.div`
  padding: ${({ theme }) => theme.paddingDesktop};

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: ${({ theme }) => theme.paddingLaptop};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding: ${({ theme }) => theme.paddingLaptop};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: ${({ theme }) => theme.paddingTablet};
  }

  @media (min-width: 1px) and (max-width: 767.98px) {
    padding: ${({ theme }) => theme.paddingPhone};
    padding-top: 0;
  }
`;

const CMSContent = styled.div`
  
  .CheckListWrapper {
    padding-top: 15px;
    padding-bottom: 17px;

    @media (max-width: 1600px) {
      padding: 0;
      padding-top: 15px;
      padding-bottom: 17px;
    }
    @media (max-width: 767.98px) {
      padding-bottom: 0px;
  }
  }
  .StyledCheckList {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .TitleBig {
    width: 100%;
    color: ${({ theme }) => theme.primaryColor};
    margin: 0 0 50px 0;
  }
  .TitleSmall {
    width: 100%;
    color: ${({ theme }) => theme.primaryColor};
    margin: 0 0 50px 0;
  }
  .StyledCheckListItem {
    margin-left: 32px;
  }
  .CheckImage {
    width: 23px;
    height: 13px;
  }
  .CheckListItemWrapper {
    display: flex;
    align-items: center;
    &> span{
      margin-bottom: 10px;
    }
    &> img{
      margin-bottom: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
	margin: 20px 20px 0 0;
    width: 256px;
`;



const Lis: React.FC<LisData> = ({ data, location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const LDAPHeaderUser = JSON.parse(data.parent.author.node.ldapUser)[0];
  const { title, subtitle, description, lisaboutlistitem1img, lisaboutlistitem2img, lisaboutlistitem3img,
    lisaboutlistitem1title, lisaboutlistitem2title, lisaboutlistitem3title, lisaboutlistitem1text,
    lisaboutlistitem2text, lisaboutlistitem3text, lisaboutlistitemstitle, lisaboutlistitem4title, lisaboutlistitem4text, lisaboutlistitem4img, reportimage } = data.parent.lis

  const LISAboutListItems = [
    {
      title: lisaboutlistitem1title,
      content: <span>{lisaboutlistitem1text}</span>,
      imageUrl: lisaboutlistitem1img.localFile.childImageSharp.fixed
    },
    {
      title: lisaboutlistitem2title,
      content: <span>{lisaboutlistitem2text}</span>,
      imageUrl: lisaboutlistitem2img.localFile.childImageSharp.fixed
    },
    {
      title: lisaboutlistitem3title,
      content: <><span>{lisaboutlistitem3text}</span>
        <Img fluid={reportimage.localFile.childImageSharp.fluid} alt="Lis raport" loading="eager" style={{ maxWidth: "550px" }} /></>,
      imageUrl: lisaboutlistitem3img.localFile.childImageSharp.fixed
    },
    {
      title: lisaboutlistitem4title,
      content: <><CMSContent dangerouslySetInnerHTML={{ __html: lisaboutlistitem4text }}></CMSContent></>,
      imageUrl: lisaboutlistitem4img.localFile.childImageSharp.fixed
    },
  ]

  return (
    <Layout>
      <SEO title={title} description={description} />
      
      <Header
        title={title}
        description={description}
        subTitle={subtitle}
        buttonLink="https://tools.databout.pl/"
        buttonText={t('runIt')}
        headerImage={lisHeader}
        headerImageWidth={700}
        isSignature={true}
        user={data.parent.author.node}
        signatureAuthor={LDAPHeaderUser.name}
        signaturePosition={LDAPHeaderUser.title}
        signatureDeskPhoneNumber={LDAPHeaderUser.telephoneNumber?.[0]}
        signaturePhoneNumber={LDAPHeaderUser.mobile?.[0]}
        signatureEmail={LDAPHeaderUser.mail}
        signatureCity={LDAPHeaderUser.physicalDeliveryOfficeName}
        signatureShareLink={location.href}
        SEOTitle={title}
        SEODescription={description}
        isArrowNavVisible={true}
        firstHeaderNavLink="tools"
        firstHeaderNavLinkName="Tools"
      />
      
      <PageContent>
        <AboutList list={LISAboutListItems} title={lisaboutlistitemstitle} />
        {data.newestNews.nodes.length > 0 && language === defaultLanguage && <NewestNews posts={data.newestNews.nodes.map(getPostModel)} />}
        <DataboutTools title={t('serviceAd.title')} subTitle={t('serviceAd.subTitle')} description={t('serviceAd.description')} link="/uslugi" />
        <NeedMore />
      </PageContent>
    </Layout>
  );
}

export default Lis;


export const pageQuery = graphql`
query($language: String!) {
    parent: wpPage(slug: {in: ["lis-pl", "lis-en"]}, language: {slug: {eq: $language}}) {
        content
		    ...AuthorPage
        ...Lis
	}
  newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}, tags: {nodes: {elemMatch: {name: {eq: "tools"}}}}}
    limit: 3
  ) {
      nodes {
        ...PageItems
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`




